import {Directive, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {ConfirmOptions, ConfirmService} from './confirm.service';

@Directive({
    selector: '[appConfirm]',
})

export class ConfirmDirective implements OnInit {
    @Output() appConfirm = new EventEmitter();
    @Input() appConfirmOptions: ConfirmOptions;

    constructor(private confirmService: ConfirmService) {
    }

    ngOnInit() {
    }

    @HostListener('click', ['$event'])
    clickEvent(event) {
        event.preventDefault();
        event.stopPropagation();
        this.confirmService.requestConfirm(this.appConfirmOptions).subscribe(() => this.appConfirm.emit());
    }
}
